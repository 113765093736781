import { trackingHttp } from 'utils/http';

export const getShipmentTrackingData = ({ trackingNumber, lang }) => {
  return trackingHttp.get(`/shipments/track/${trackingNumber}?lang=${lang}`);
};

export const generateOTP = (payload) => {
  return trackingHttp.post('/shipments/generate-otp', payload);
};

export const validateOTP = (payload) => {
  return trackingHttp.post('/shipments/validate-otp', payload);
};

export const getAvailableRescheduledDates = ({ token, payload }) => {
  return trackingHttp.get(
    '/shipments/get-available-rescheduled-dates',
    payload,
    { headers: { Authorization: token } }
  );
};

export const rescheduleShipment = ({ token, payload }) => {
  return trackingHttp.put('/shipments/reschedule-shipment', payload, {
    headers: { Authorization: token }
  });
};

export const editShipmentAddress = ({ token, payload }) => {
  return trackingHttp.put('/shipments/edit-shipment-address', payload, {
    headers: { Authorization: token }
  });
};

export const markAsFakeException = ({ token }) => {
  return trackingHttp.post(
    '/shipments/markAsfakeException',
    {},
    { headers: { Authorization: token } }
  );
};

export const getStarInfo = ({ token, payload }) => {
  return trackingHttp.get('/shipments/get-star-info', payload, {
    headers: { Authorization: token }
  });
};

export const requestPOS = ({ token, payload }) => {
  return trackingHttp.put(`/shipments/request-pos-machine`, payload, {
    headers: { Authorization: token }
  });
};

export const getOrderCod = ({ token, payload }) => {
  return trackingHttp.get('/shipments/get-order-cod', payload, {
    headers: { Authorization: token }
  });
};

export const getOrderAddress = ({ token, payload }) => {
  return trackingHttp.get('/shipments/shipment-address', payload, {
    headers: { Authorization: token }
  });
};

export const rateService = ({ trackingNumber, payload }) => {
  return trackingHttp.post(`/shipments/${trackingNumber}/rating`, payload);
};

export const requestToPayOnline = ({ token, payload }) => {
  return trackingHttp.post(`/shipments/pay`, payload, {
    headers: { Authorization: token }
  });
};
