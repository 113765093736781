import { Modal, Form, Rate, Input } from 'antd';
import { useIntl } from 'react-intl';
import { useState } from 'react';

import { rateService } from 'services/shipments';
import { openModal } from 'utils/modal';

import BRButton from 'components/BRButton/BRButton';
import RateConfirmationModal from 'components/RateDeliveryServiceModal/components/RateConfirmationModal/RateConfirmationModal';
import { notify } from 'components/Notify/Notify';

import './RateDeliveryServiceModal.less';

const RateDeliveryServiceModal = ({
  close,
  trackingNumber,
  ratingOtp,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      const payload = {
        ...values,
        otp: Number(ratingOtp)
      };
      await rateService({ trackingNumber, payload });
      openModal(RateConfirmationModal);
      close();
    } catch (error) {
      notify({ msg: error.message, error });
    }

    setIsLoading(false);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'rate_delivery_service_modal.title' })}
      wrapClassName="br-rate-delivery-service__modal"
      footer={
        <>
          <BRButton
            label={intl.formatMessage({ id: 'common.cancel' })}
            onClick={close}
          />
          <BRButton
            type="primary"
            loading={isLoading}
            label={intl.formatMessage({
              id: 'rate_delivery_service_modal.form_labels.confirm'
            })}
            form="rateDeliveryServiceForm"
            htmlType="submit"
          />
        </>
      }
      {...props}
    >
      <Form onFinish={onFinish} name="rateDeliveryServiceForm">
        <Form.Item
          name="starRating"
          rules={[{ required: true }]}
          label={intl.formatMessage({
            id: 'rate_delivery_service_modal.form_labels.rate_agent'
          })}
        >
          <Rate />
        </Form.Item>
        <Form.Item
          name="starComment"
          label={
            <>
              <span>
                {intl.formatMessage({
                  id: 'rate_delivery_service_modal.form_labels.rate_comment'
                })}
              </span>
              <span className="br-form-optional-label">
                {intl.formatMessage({
                  id: 'form.optional_label'
                })}
              </span>
            </>
          }
        >
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 3 }}
            maxLength={500}
          />
        </Form.Item>
        <Form.Item
          name="serviceRating"
          rules={[{ required: true }]}
          label={intl.formatMessage({
            id: 'rate_delivery_service_modal.form_labels.rate_service'
          })}
        >
          <Rate />
        </Form.Item>
        <Form.Item
          name="serviceComment"
          label={
            <>
              <span>
                {intl.formatMessage({
                  id: 'rate_delivery_service_modal.form_labels.rate_comment'
                })}
              </span>
              <span className="br-form-optional-label">
                {intl.formatMessage({
                  id: 'form.optional_label'
                })}
              </span>
            </>
          }
        >
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 3 }}
            maxLength={500}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RateDeliveryServiceModal;
