import { injectIntl } from 'react-intl';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import Icon from '@ant-design/icons';

import { dates, DATE_FORMAT_WITH_DAY_WITHOUT_YEAR } from 'utils/helpers';
import { openModal } from 'utils/modal';
import { isDashboardArabic } from 'utils/intl-wrapper';
import { convertEnglishNumbersToArabic } from 'utils/numbers';
import { sendSegment } from 'utils/segment';

import BRButton from 'components/BRButton/BRButton';
import DateEditModal from 'components/ShipmentTracking/components/DateEditModal/DateEditModal';
import AddressEditModal from 'components/ShipmentTracking/components/AddressEditModal/AddressEditModal';

import { ReactComponent as HiddenEyeIcon } from 'assets/icons/HiddenEyeIcon.svg';
import { ReactComponent as TooltipIcon } from 'assets/icons/Tooltip.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/CalendarIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/EditIcon.svg';

import './DateAddressEdit.less';
import { getUserCurrency } from 'constants/countries/countries-mapping';

const DateAddressEdit = ({
  intl,
  trackingDetails,
  getTrackingData,
  apiKey,
  verifyIdentity,
  codAmount,
  dropOffAddress
}) => {
  const { PromisedDate, nextWorkingDay } = trackingDetails || {};

  const handleChangeDateClick = () => {
    sendSegment('Consignee_changes_delivery_date', {
      Source: 'Consignee_tracking_page'
    });

    openModal(DateEditModal, {
      apiKey,
      onSuccess: getTrackingData
    });
  };

  const handleChangeAddressClick = () => {
    sendSegment('Consignee_change address', {
      Source: 'Consignee_tracking_page'
    });

    openModal(AddressEditModal, {
      apiKey,
      dropOffAddress,
      onSuccess: getTrackingData
    });
  };

  const renderAddress = () => {
    const {
      apartment,
      floor,
      buildingNumber,
      firstLine,
      secondLine,
      district = {},
      city = {},
      zone = {}
    } = dropOffAddress || {};

    const districtName =
      isDashboardArabic() && district.nameAr ? district.nameAr : district.name;
    const cityName =
      isDashboardArabic() && city.nameAr ? city.nameAr : city.name;
    const zoneName =
      isDashboardArabic() && zone.nameAr ? zone.nameAr : zone.name;

    const format = (arr = []) => arr.filter((item) => item).join(', ');
    return (
      <>
        <div>
          {format([
            ...(buildingNumber
              ? [convertEnglishNumbersToArabic(buildingNumber)]
              : []),
            firstLine,
            secondLine
          ])}
        </div>
        {format([
          ...(floor
            ? [
                `${intl.formatMessage({
                  id: 'address_details.floor_placeholder'
                })}: ${convertEnglishNumbersToArabic(floor)}`
              ]
            : []),
          ...(apartment
            ? [
                `${intl.formatMessage({
                  id: 'address_details.apartment'
                })}: ${convertEnglishNumbersToArabic(apartment)}`
              ]
            : [])
        ])}
        <div>
          {format([zoneName ? `${zoneName}` : null, districtName, cityName])}
        </div>
      </>
    );
  };

  return (
    <div className="br-date-address-edit">
      <div className="br-date-address-edit__title heading">
        {intl.formatMessage({ id: 'tracking.date_address_edit.title' })}
      </div>
      <div
        className={classNames('br-date-address-edit__data-show', {
          'show-data': apiKey
        })}
      >
        <div className="data-show__data">
          <div className="data-show__data__key">
            {intl.formatMessage({
              id: 'tracking.date_address_edit.address.title'
            })}
          </div>
          <div className="data-show__data__value">
            {apiKey ? (
              <>
                {renderAddress()}
                <BRButton
                  label={intl.formatMessage({
                    id: 'tracking.date_address_edit.address.edit_btn'
                  })}
                  prefixIcon={<EditIcon />}
                  onClick={handleChangeAddressClick}
                />
              </>
            ) : (
              <div className="data-show__data__hidden-address">
                <HiddenEyeIcon />
                {intl.formatMessage({
                  id: 'tracking.date_address_edit.address.hidden'
                })}
                <Tooltip
                  title={intl.formatMessage({
                    id: 'tracking.date_address_edit.address.tooltip'
                  })}
                  overlayClassName="data-show__data__hidden-address__tooltip"
                >
                  <Icon
                    component={TooltipIcon}
                    className="ant-icon-md br-tracking-alert-icon"
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className="data-show__vertical-divider" />
        <div className="data-show__data">
          <div className="data-show__data__key">
            {intl.formatMessage({
              id: 'tracking.date_address_edit.date.title'
            })}
          </div>
          <div className="data-show__data__value">
            {dates(PromisedDate, DATE_FORMAT_WITH_DAY_WITHOUT_YEAR)}{' '}
            {nextWorkingDay &&
              `- ${dates(
                nextWorkingDay[0].dayDate,
                DATE_FORMAT_WITH_DAY_WITHOUT_YEAR
              )}`}
            {apiKey && (
              <BRButton
                label={intl.formatMessage({
                  id: 'tracking.date_address_edit.date.edit_btn'
                })}
                prefixIcon={<CalendarIcon />}
                onClick={handleChangeDateClick}
              />
            )}
          </div>
        </div>
        <div className="data-show__vertical-divider" />
        <div className="data-show__data">
          <div className="data-show__data__key">
            {intl.formatMessage({
              id: 'tracking.order_amount.title'
            })}
          </div>
          {apiKey ? (
            getUserCurrency(codAmount).localized
          ) : (
            <div className="data-show__data__hidden-address">
              <HiddenEyeIcon />
              {intl.formatMessage({
                id: 'tracking.order_amount.hidden_amount'
              })}
              <Tooltip
                title={intl.formatMessage({
                  id: 'tracking.order_amount.hidden_amount_tooltip'
                })}
                overlayClassName="data-show__data__hidden-address__tooltip"
              >
                <Icon
                  component={TooltipIcon}
                  className="ant-icon-md br-tracking-alert-icon"
                />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      {!apiKey && (
        <div className="br-date-address-edit__identity-verification">
          <Icon component={TooltipIcon} className="ant-icon-md" />
          <div className="br-date-address-edit__identity-verification-content">
            {intl.formatMessage({
              id: 'tracking.date_address_edit.identity_verification.subtitle'
            })}
            <BRButton
              className="button-md"
              label={intl.formatMessage({
                id: 'tracking.date_address_edit.identity_verification.action'
              })}
              onClick={verifyIdentity}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default injectIntl(DateAddressEdit);
